import { inject, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AdverseEvent,
  AdverseEventList, AdverseEventResponse, AdverseEventSeverityList, ArmList,
  BasicIdNameList,
  FormQuestionList,
  GenericId,
  GetParticipantFormResultsResponse,
  ListParticipantResponse,
  ParticipantSessionList,
  PostParticipantFormResultsResponse,
  SiteList,
  SiteResponse,
  StudyFormList,
  StudyList,
  StudyResponse,
  WithdrawnData
} from "./api/api.interfaces";
import { PatientEdDiagnoseList } from '@models/participant.model';
import {cu} from "@fullcalendar/core/internal-common";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  http = inject(HttpClient);

  constructor() {
  }

  siteListing(params: any): Observable<SiteList> {
    const signInUrl = `${environment.apiUrl}sites`;
    return this.http.get<SiteList>(signInUrl, { params: params });
  }

  participants(params: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}participants`;
    return this.http.get(signInUrl, { params: params });
  }

  studyListing(params: any): Observable<StudyList> {
    const signInUrl = `${environment.apiUrl}studies`;
    return this.http.get<StudyList>(signInUrl, { params: params });
  }

  measurements(params: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}lists/measurements`;
    return this.http.get(signInUrl, { params: params });
  }

  getvideodiarytopics(params: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}lists/video-diary-topics`;
    return this.http.get(signInUrl, { params: params });
  }

  surveys(params: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}lists/surveys`;
    return this.http.get(signInUrl, { params: params });
  }

  postStudy(information: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}studies`;
    return this.http.post(signInUrl, information);
  }

  countries(): Observable<any> {
    const countries = `${environment.apiUrl}lists/countries`;
    return this.http.get(countries);
  }

  postSite(information: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites`;
    return this.http.post(signInUrl, information);
  }

  putSite(information: any, id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites/${id}`;
    return this.http.put(signInUrl, information);
  }

  getSiteById(id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites/${id}`;
    return this.http.get(signInUrl);
  }

  getClinicalDevicesId(id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites/${id}/clinical-devices`;
    return this.http.get(signInUrl);
  }


  deleteClinicalDevicesId(id: number, deleteId: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites/${id}/clinical-devices/${deleteId}`;
    return this.http.delete(signInUrl);
  }

  putClinicalDevicesId(id: number, deleteId: number, data: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites/${id}/clinical-devices/${deleteId}`;
    return this.http.put(signInUrl, data);
  }

  deleteClinicalDevices(id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites/${id}/clinical-devices`;
    return this.http.delete(signInUrl);
  }

  addClinicalDevices(id: number, data): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites/${id}/clinical-devices`;
    return this.http.post(signInUrl, data);
  }

  studyManagerListing(): Observable<any> {
    const signInUrl = `${environment.apiUrl}users?role=Study Manager&size=99999`;
    return this.http.get(signInUrl);
  }

  getScreenings(): Observable<any> {
    const signInUrl = `${environment.apiUrl}lists/forms?type=screenings`;
    return this.http.get(signInUrl);
  }

  getdemographics(): Observable<any> {
    const signInUrl = `${environment.apiUrl}lists/forms?type=demographics`;
    return this.http.get(signInUrl);
  }

  getmeasurements(): Observable<any> {
    const signInUrl = `${environment.apiUrl}lists/forms?type=measurements`;
    return this.http.get(signInUrl);
  }

  postParticipant(studyId: number, siteId: number): Observable<GenericId> {
    const url = `${environment.apiUrl}participants`;
    return this.http.post<GenericId>(url, {
      study: studyId,
      site: siteId
    });
  }

  putParticipant(participantId: number, payload: any): Observable<GenericId> {
    const url = `${environment.apiUrl}participants/${participantId}`;
    return this.http.put<GenericId>(url, payload);
  }

  activateParticipant(participantId: number): Observable<GenericId> {
    const url = `${environment.apiUrl}participants/${participantId}/activate`;
    return this.http.post<GenericId>(url, {});
  }

  withdrawParticipant(participantId: number, withdrawnData: WithdrawnData): Observable<GenericId> {
    const url = `${environment.apiUrl}participants/${participantId}/withdraw`;
    return this.http.post<GenericId>(url, withdrawnData);
  }

  completeParticipant(participantId: number): Observable<GenericId> {
    const url = `${environment.apiUrl}participants/${participantId}/complete-study`;
    return this.http.post<GenericId>(url, {});
  }

  resendSignedICF(participantId: number): Observable<GenericId> {
    const url = `${environment.apiUrl}participants/${participantId}/resend-signed-icf`;
    return this.http.get<GenericId>(url);
  }

  getStudyById(id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}studies/${id}`;
    return this.http.get(signInUrl);
  }

  listParticipantSessions(participantId: number, start: number, sort: string, order: string): Observable<ParticipantSessionList> {
    const url = `${environment.apiUrl}sessions?participant=${participantId}&start=${start}&sort=${sort}&order=${order}`;
    return this.http.get<ParticipantSessionList>(url);
  }

  listParticipantAdverseEvents(participantId: number, start: number, sort: string, order: string): Observable<AdverseEventList> {
    const url = `${environment.apiUrl}participants/${participantId}/adverse-events?start=${start}&sort=${sort}&order=${order}`;
    return this.http.get<AdverseEventList>(url);
  }

  getParticipantAdverseEvent(participantId: number, adverseEventId: number): Observable<AdverseEventResponse> {
    const url = `${environment.apiUrl}participants/${participantId}/adverse-events/${adverseEventId}`;
    return this.http.get<AdverseEventResponse>(url);
  }

  putStudy(information: any, id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}studies/${id}`;
    return this.http.put(signInUrl, information);
  }

  postSession(information: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}sessions`;
    return this.http.post(signInUrl, information);
  }

  getStudySiteById(id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites?study=${id}&size=99999`;
    return this.http.get(signInUrl);
  }

  getStudySiteByIdQueryparm(params: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}sites`;
    return this.http.get(signInUrl, { params });
  }


  postForms(information: any, id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}studies/${id}/forms`;
    return this.http.post(signInUrl, information);
  }

  getForms(id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}studies/${id}/forms `;
    return this.http.get(signInUrl);
  }

  deleteStudyById(id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}studies/${id}`;
    return this.http.delete(signInUrl);
  }

  deletesessionById(id: number): Observable<any> {
    const signInUrl = `${environment.apiUrl}sessions/${id}`;
    return this.http.delete(signInUrl);
  }

  editSession(information: any): Observable<any> {
    const signInUrl = `${environment.apiUrl}sessions/${information.id}`;
    return this.http.put(signInUrl, information);
  }

  listStudyForms(studyId: number, type: string): Observable<StudyFormList> {
    const url = `${environment.apiUrl}studies/${studyId}/forms?type=${type}`;
    return this.http.get<StudyFormList>(url);
  }

  listFormQuestions(formId: number): Observable<FormQuestionList> {
    const url = `${environment.apiUrl}forms/${formId}/questions`;
    return this.http.get<FormQuestionList>(url);
  }

  postParticipantFormResults(participantId: number, formId: number, results: any): Observable<PostParticipantFormResultsResponse> {
    const url = `${environment.apiUrl}participants/${participantId}/forms/${formId}/results`;
    return this.http.post<PostParticipantFormResultsResponse>(url, {
      questions: results,
    });
  }

  getParticipantFormResults(participantId: number, formId: number): Observable<GetParticipantFormResultsResponse> {
    const url = `${environment.apiUrl}participants/${participantId}/forms/${formId}/results?size=1000`;
    return this.http.get<GetParticipantFormResultsResponse>(url);
  }

  listEdDiagnoses(): Observable<PatientEdDiagnoseList> {
    const url = `${environment.apiUrl}lists/ed-diagnoses`;
    return this.http.get<PatientEdDiagnoseList>(url);
  }

  listFoodIntakeStatus(): Observable<BasicIdNameList> {
    const url = `${environment.apiUrl}lists/food-intake-status`;
    return this.http.get<BasicIdNameList>(url);
  }

  listComorbidities(): Observable<BasicIdNameList> {
    const url = `${environment.apiUrl}lists/comorbidities`;
    return this.http.get<BasicIdNameList>(url);
  }

  listParticipantStatus(): Observable<BasicIdNameList> {
    const url = `${environment.apiUrl}lists/participant-status`;
    return this.http.get<BasicIdNameList>(url);
  }

  listCarerRelationships(): Observable<BasicIdNameList> {
    const url = `${environment.apiUrl}lists/carer-relationships`;
    return this.http.get<BasicIdNameList>(url);
  }

  getSite(id: number): Observable<SiteResponse> {
    const url = `${environment.apiUrl}sites/${id}`;
    return this.http.get<SiteResponse>(url);
  }

  getStudy(id: number): Observable<StudyResponse> {
    const url = `${environment.apiUrl}studies/${id}`;
    return this.http.get<StudyResponse>(url);
  }

  listParticipants(start: number, sort: string, order: string, filter?: object): Observable<ListParticipantResponse> {
    // @ts-ignore
    const url = `${environment.apiUrl}participants?start=${start}&sort=${sort}&order=${order}&` + new URLSearchParams(filter);
    return this.http.get<ListParticipantResponse>(url);
  }

  listArms(): Observable<ArmList> {
    const url = `${environment.apiUrl}lists/study-arms-library?start=0&size=1000`;
    return this.http.get<ArmList>(url);
  }

  listAdverseEventSeverities(): Observable<AdverseEventSeverityList> {
    const url = `${environment.apiUrl}lists/adverse-event-severities?start=0&size=1000`;
    return this.http.get<AdverseEventSeverityList>(url);
  }

  postAdverseEvent(participantId: number, payload: AdverseEvent): Observable<GenericId> {
    const url = `${environment.apiUrl}participants/${participantId}/adverse-events`;
    return this.http.post<GenericId>(url, payload);
  }

  putAdverseEvent(participantId: number, adverseEventId: number, payload: AdverseEvent): Observable<GenericId> {
    const url = `${environment.apiUrl}participants/${participantId}/adverse-events/${adverseEventId}`;
    return this.http.put<GenericId>(url, payload);
  }

  deleteAdverseEvent(participantId: number, adverseEventId: number): Observable<GenericId> {
    const url = `${environment.apiUrl}participants/${participantId}/adverse-events/${adverseEventId}`;
    return this.http.delete<GenericId>(url);
  }

  changePassword(currentPassword: string, newPassword: string, repeatPassword: string): Observable<GenericId> {
    const url = `${environment.apiUrl}change-password`;
    return this.http.post<GenericId>(url, {
      currentPassword: currentPassword,
      password: newPassword,
      passwordRepeat: repeatPassword
    });
  }

  putCurrentUser(firstName: string, lastName: string, email: string, role: string): Observable<GenericId> {
    const url = `${environment.apiUrl}users/me`;
    return this.http.put<GenericId>(url, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: role
    });
  }
}
